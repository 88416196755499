<template>
  <div class="dialog-add-account" v-loading="loading">
    <div class="dialog-add-account__title" v-html="$t('transactionDialog.title')" />

    <div class="dialog-add-account__desc">
      {{ $t('transactionDialog.description') }}
    </div>

    <div class="dialog-add-account__data-picker">
      <span class="dialog-add-account__data-title">{{ $t('transactionDialog.dateLabel') }}:</span>
      <date-range-picker 
        class="statistics__period-calendar" 
        :class="{ 'statistics__period-calendar--open': isShow }"
        ref="picker" 
        v-model="date" 
        single-date-picker="range" 
        :auto-apply="true" 
        :always-show-calendars="true"
        :ranges="false" 
        :locale-data="locales[$i18n._vm.locale]"   
        @toggle="isShow = !isShow" opens="left"
      >
        <template #input="picker">
          {{ fixDate(picker.startDate, picker.endDate) }}
        </template>
      </date-range-picker>
    </div>
    <div class="dialog-add-account__button-wrapper">
      <span class="error" v-if="dataError">{{ respErr }}</span>
      <ButtonElement 
        :text="$t('transactionDialog.uploadReport')" 
        :disabled="!date.endDate && !date.startDate" 
        view="empty"
        :download="true"
        @click-button="downloadFile" 
      />
    </div>
  </div>
</template>
  
<script>
import formValidate from '../../mixin/formValidate'
import DateRangePicker from 'vue2-daterange-picker'
import ButtonElement from '../../components/elements/ButtonElement'
import axios from 'axios'


const apiUrl = process.env.VUE_APP_API_URL

export default {
  name: 'TransactionsXLS',
  components: {
    DateRangePicker,
    ButtonElement
  },
  mixins: [formValidate],
  data() {
    return {
      lang: this.$i18n._vm.locale,
      loading: false,
      isShow: false,
      respErr: '',
      dataError: false,
      currentDate: '',
      hours: new Date().getHours(),
      minutes: new Date().getMinutes(),
      seconds: new Date().getSeconds(),
      isDisabled: false,
      date: {
        startDate: new Date(),
        endDate: new Date()
      },
      minDate: null,
      locales: {
        ru: {
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
          daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
          monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
          firstDay: 1
        },
        en: {
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
          daysOfWeek: ['sun', 'mon', 'tu', 'wen', 'th', 'fr', 'sat'],
          monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
          firstDay: 1
        }
      },
      months: {
        ru: [
          {
            id: 0,
            shortName: 'янв'
          },
          {
            id: 1,
            shortName: 'фев'
          },
          {
            id: 2,
            shortName: 'мар'
          },
          {
            id: 3,
            shortName: 'апр'
          },
          {
            id: 4,
            shortName: 'май'
          },
          {
            id: 5,
            shortName: 'июн'
          },
          {
            id: 6,
            shortName: 'июл'
          },
          {
            id: 7,
            shortName: 'авг'
          },
          {
            id: 8,
            shortName: 'сен'
          },
          {
            id: 9,
            shortName: 'окт'
          },
          {
            id: 10,
            shortName: 'нояб'
          },
          {
            id: 11,
            shortName: 'дек'
          }
        ],
        en:[
          {
            id: 0,
            shortName: 'jan'
          },
          {
            id: 1,
            shortName: 'feb'
          },
          {
            id: 2,
            shortName: 'mar'
          },
          {
            id: 3,
            shortName: 'apr'
          },
          {
            id: 4,
            shortName: 'may'
          },
          {
            id: 5,
            shortName: 'jun'
          },
          {
            id: 6,
            shortName: 'jul'
          },
          {
            id: 7,
            shortName: 'aug'
          },
          {
            id: 8,
            shortName: 'sep'
          },
          {
            id: 9,
            shortName: 'oct'
          },
          {
            id: 10,
            shortName: 'nov'
          },
          {
            id: 11,
            shortName: 'dec'
          }
        ],
      },
      activePeriod: 'day',
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    start_at() {
      return this.fixDateFormatNumbers(this.date.startDate)
    },
    end_at() {
      return this.fixDateFormatNumbers(this.date.endDate)
    }
  },

  created() {
    this.currentDate = this.fixDateFormatNumbers(new Date())
  },
  methods: {
    closeModal() {
      this.$store.dispatch('Dialog/closeDialog')
    },

    fixDate(start, end) {
      if (start && end) {
        let str = ''
        const startDay = start.getDate()
        const endDay = end.getDate()

        const startMonth = start.getMonth()
        const endMonth = end.getMonth()
        const nameStartMonth = this.months[this.$i18n._vm.locale].find(el => el.id === startMonth).shortName
        const nameEndMonth = this.months[this.$i18n._vm.locale].find(el => el.id === endMonth).shortName

        if (startDay === endDay && startMonth === endMonth) {
          str = startDay + ' ' + nameStartMonth
        } else if (startMonth === endMonth) {
          str = startDay + '-' + endDay + ' ' + nameStartMonth
        } else {
          str = startDay + ' ' + nameStartMonth + ' - ' + endDay + ' ' + nameEndMonth
        }
        return str
      }
    },
    fixDateFormatNumbers(fixedDate) {
      if (fixedDate) {
        let date = new Date(fixedDate)
        let str = ''
        const startDay = String(date.getDate()).padStart(2, '0')
        const startMonth = String(date.getMonth() + 1).padStart(2, '0');
        const startYear = date.getFullYear()
        str = startYear + '-' + startMonth + '-' + startDay
        return str
      }
    },
    downloadFile() {
      this.loading = true
      const token = localStorage.getItem('token') ||  localStorage.getItem('client_token')
      const params = {
        business_account_id: this.activeAccount.id,
        date_from: this.fixDateFormatNumbers(this.date.startDate),
        date_to: this.fixDateFormatNumbers(this.date.endDate),
      }
      axios({ url: `${apiUrl}/transactions/export`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
        .then(resp => {
          this.dataError = false
          this.isDisabled = true
          const link = document.createElement('a');
          link.href = resp.data.data.url;
          link.download = 'transactions.xlsx';
          document.body.appendChild(link);
          link.click();
          this.$store.dispatch('Dialog/closeDialog')
        })
        .catch(err => {
          console.log(err.response)
          this.dataError = true
          this.respErr = err.response.statusText
          this.isDisabled = false
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
  
<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

@keyframes settingsRotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.error {
  color: red;
}

.save-users-data {
  border: 1px solid #d8d9da;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  border-radius: 4px;
  height: 3.5rem;
  min-width: 12.5rem;
  text-align: center;
  padding-top: 7px;
  margin-right: 1rem;
}


.dialog-add-account {
  &__data-picker {
    display: flex;
    justify-content: space-between;
  }

  &__data-title {
    font-weight: 700;
  }

  &__button-wrapper {
    display: flex;
    margin-top: 3rem;

    .button {
      margin-left: auto;
      padding: 0 20px;
      height: 40px;
    }
  }
}
</style>
  